@font-face {
  font-family: HelveticaNeueLTStdMdIt;
  src: url('/public/fonts/HelveticaNeueLTStd-MdIt.otf');
}

@font-face {
  font-family: SourceSansVariableRoman;
  src: url('/public/fonts/SourceSansVariable-Roman.otf');
}

@media screen and (max-width: 600px) {
  @property --bodyWidth {
    syntax: "<number>";
    inherits: false;
    initial-value: 600;
  }

  @property --bodyHeight {
    syntax: "<number>";
    inherits: false;
    initial-value: 1024;
  }

  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1rem;
  }

  .multiCheckboxLabel {
    font-size: 1.2em;
  }

  .selectTopic {
    margin-top: 18%;
    color: white;
    font-size: 4.3em;
    font-family: SourceSansVariableRoman;
    font-weight: 700;
    
  }

  .topicTextRelative {
    color: #05314D;
    width:100%;
    height:45%;
    position: absolute;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    top: 6%;
  }

  .pageContainer {
    position: absolute;
    width: 600px;
    height: 1024px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: url('/public/background_1024.png');
    background-size: cover;
  }

  .summaryContainer {
    position: absolute;
    width: 600px;
    height: 1024px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: url('/public/images/topicBg_600px.png');
    /* background-size: cover  */
  }

  .questionContainer {
    position: absolute;
    width: 600px;
    height: 1024px;
    top: 0;
    left: 0;
    overflow: hidden;
    /* background-position-y: -28px; */
    background-image: url('/public/images/questionBg_600px.png');
    background-size: cover
  }

  .whiteMultipleChoiceButton {
    background-color: white;
    border-radius: 5px;
    color: #05314D;
    border-width: 1px;
    border-color: #05314D;
    font-size: 1.5rem;
    line-height: 2.5rem;
    width: 100%;
    margin-bottom: 1rem;
    text-align: left;
    padding-left: 2em;
    text-indent: -2rem;
    min-height: 5rem;
  }

  body {
    position: absolute;
    width: 600px;
    height: 1024px;
  }

  .thankYouContainer {
    background-image: url('/src/assets/thankyou_600.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media screen and (min-width: 601px) {
  @property --bodyWidth {
    syntax: "<number>";
    inherits: false;
    initial-value: 1080;
  }

  @property --bodyHeight {
    syntax: "<number>";
    inherits: false;
    initial-value: 1920;
  }
  h1 {
    font-size: 7.5em;
  }
  
  h2 {
    font-size: 5em;
  }
  
  h3 {
    font-size: 2.4em;
  }

  .multiCheckboxLabel {
    font-size: 2em;
  }

  .selectTopic {
    margin-top: 18%;
    color: white;
    font-size: 144px;
    font-family: SourceSansVariableRoman;
    font-weight: 700;
    
  }
  
  .topicTextRelative {
    color: #05314D;
    width:100%;
    height:45%;
    position: absolute;
    text-align: center;
    font-size: 44px;
    font-weight: 700;
    line-height: 46px;
    top: 5%;
  }

  .pageContainer {
    position: absolute;
    width: 1080px;
    height: 1920px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: url('/public/background_1024.png');
    background-size: cover
  }

  .summaryContainer {
    position: absolute;
    width: 1080px;
    height: 1920px;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: url('/public/images/topicBg_1080px.png');
    /* background-size: cover */
  }

  .questionContainer {
    position: absolute;
    width: 1080px;
    height: 1920px;
    top: 0;
    left: 0;
    overflow: hidden;
    /* background-position-y: -28px; */
    background-image: url('/public/images/questionnaireBgNew.png');
    /* background-size: cover */
  }

  .questionContainer h1 {
    margin-bottom: 0;
  }
  .questionContainer h2 {
    margin-top: 0;
    margin-bottom: calc(var(--bodyHeight) * .03px);
  }
  .questionContainer h3 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: calc(var(--bodyHeight) * .03px);
  }

  .whiteMultipleChoiceButton {
    background-color: white;
    border-radius: 15px;
    color: #05314D;
    border-width: 1px;
    border-color: #05314D;
    font-size: 2.5em;
    font-weight: 500;
    line-height: 1.2em;
    width: 100%;
    margin-bottom: 1em;
    text-align: left;
    text-indent: -1em;
    padding-left: 2em;
    padding-right: 1em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 2.0em;
  }

  body {
    position: absolute;
    width: 1080px;
    height: 1920px;
  }

  .thankYouContainer {
    background-image: url('/src/assets/thankyou_1080.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


body {
  margin: 0;
  overscroll-behavior: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/public/background_1024.png');
  background-size: contain;
}


.hello {
  font-family: SourceSansVariableRoman;
  font-size: calc(var(--bodyHeight) * .11px)
}

.tellUs {
  font-family: SourceSansVariableRoman;
  font-size: calc(var(--bodyHeight) * .03px);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.video-react-button {
  display: none !important;
}

.video-react-big-play-button {
  display: none !important;
}

.video-react-loading-spinner {
  display: none !important;
}

.video-react {
  background: transparent;
}

.gridContainer {
  display: grid;
}

.flexContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /* justify-content: center; */
}

.flexContainer.welcome {
  background-image: url('/src/assets/welcomeTransitionFrame.png');
}

.flexHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.flexModuleVertical {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  height: auto;
}

.topicCityScape {
  display: flex;
  background-image: url('/public/images/topicCityScape.png');
  background-size: cover;
  background-repeat: no-repeat;
  position:absolute;
  top: 43%;
  left: 0;
  right: 0;
  bottom: 0;
}

.topicAPI {
  position: absolute;
  left: 4%;
  top: 42%;
  width: 40%;
}

.topicAPI img {
  width: 100%;
  height: auto;
  text-align: center;
}

.topicProducts {
  position: absolute;
  left: 59%;
  top: 59%;
  width: 40%;
}

.topicProducts img {
  width: 100%;
  height: auto;
  text-align: center;
}

.topicRenter {
  position: absolute;
  left: 13%;
  top: 62%;
  width: 40%;
}

.topicRenter img {
  width: 100%;
  height: auto;
  text-align: center;
}

.topicRisk {
  position: absolute;
  left: 46%;
  top: 43%;
  width: 40%;
}

.topicRisk img {
  width: 100%;
  height: auto;
  text-align: center;
}

.mainLogoContainer {
  background-image: url('/public/images/mainLogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%;
}

.topicLogoContainer {
  background-image: url('/public/images/topicLogo.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.videoOverlay {
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  pointer-events: none;
}

.posterUnderlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 3;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 3;
  background-image: url('/public/background_1024.png');
  background-size: auto;
  background-position-y: 0;
  background-position-x: center;
  pointer-events: none;
}

.fader.fadeIn {
  animation: fadeIn 1s ease-in;
}

.fader.fadeOut {
  animation: fadeOut 1s ease-in;
}

.fader.opaque {
  opacity: 1;
}

.fader.transparent {
  opacity: 0;
}

.fader.welcome {
  background-image: url('/public/background_1024.png');
}

.fader.topicSummary {
  background-image: url('/public/images/topicBg_1080px.png')
}

.fader.questionnaire {
  background-image: url('/public/images/questionnaireBgNew.png');
  /* background-position-y: -28px; */

}

.posterUnderlay img {
  width: calc(var(--bodyWidth) * 1px);
  height: auto;
}

.keyboardTheme {
  color: #05314D;
}

.topicSelect {
  position: absolute;
  z-index: 2;
}

.topicSelectText {
  position: relative;
  z-index: 3;
  pointer-events: none;
}

.whiteMultipleChoiceButton.selected {
  background-color: #E1208F;
  color: white;
  border-color: white;
}

.solidCheckbox {
  color: #fff;
  fill: #fff;
}

.solidCheckbox svg {
  color: #fff;
  fill: #fff;
}

.solidCheckbox svg path {
  fill: white !important;
}

.standardButton {
  width: calc(var(--bodyWidth) * .25px);
  height: auto;
}

.selectLabel {
  font-size: calc(var(--bodyWidth) * .03px);
}

.topicDescription {
  font-size: calc(var(--bodyHeight) * .03px);
  width: calc(var(--bodyWidth) * .86px);
  margin-left: calc(var(--bodyWidth) * .07px);
  margin-bottom: 2em;
}

.questionContainer ul {
  text-align: start;
  width: 65%;
  margin-left: 15%;
}